<template>
    <b-nav-item>
        <b-button
            @click="skin = isDark ? 'light' : 'dark'"
            class="btn-icon rounded-circle"
            variant="flat-primary"
            v-b-tooltip.hover
            :title="`${isDark ? 'Dark' : 'Light'}`"
        >
            <b-icon-sun-fill v-if="skin == 'light'"></b-icon-sun-fill>
            <b-icon-moon-stars-fill v-else></b-icon-moon-stars-fill>
            <!-- <feather-icon
                :icon="`${isDark ? 'Sun' : 'Moon'}Icon`"
                size="21"
            /> -->
        </b-button>
    </b-nav-item>
</template>

<script>
import useAppConfig from "@core/app-config/useAppConfig";
import { computed } from "@vue/composition-api";
import { BNavItem } from "bootstrap-vue";

export default {
    components: {
        BNavItem,
    },
    setup() {
        const { skin } = useAppConfig();

        const isDark = computed(() => skin.value === "dark");

        return {
            skin,
            isDark,
        };
    },
};
</script>
